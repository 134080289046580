import React from "react";
import "./style.css";

const Home: React.FC = () => {
  return (
    <div className="homeContainer">
      <aside className="sidebar">
        <img src="logo.png" alt="Logo" className="sidebarLogo" />
        <nav className="sidebarNav">
          <ul>
            {[
              "Início",
              "Explorar",
              "Notificações",
              "Mensagens",
              "Perfil",
              "Configurações",
            ].map((item, index) => (
              <li key={index}>
                <a href={`/${item.toLowerCase()}`}>{item}</a>
              </li>
            ))}
          </ul>
        </nav>
        <button className="button tweetButton">Nova Postagem</button>
      </aside>

      <main className="feed">
        <header className="feedHeader">
          <h1>Início</h1>
          <button className="filterButton">Filtrar Posts</button>
        </header>
        <section className="postBox">
          <textarea
            className="postInput"
            placeholder="Compartilhe algo novo..."
          ></textarea>
          <button className="button postButton">Publicar</button>
        </section>
        <section className="posts">
          {[...Array(3)].map((_, index) => (
            <article className="post" key={index}>
              <div className="postHeader">
                <strong>Usuário {index + 1}</strong>{" "}
                <span>@usuario{index + 1}</span>
              </div>
              <p>Esta é uma postagem de exemplo.</p>
            </article>
          ))}
        </section>
      </main>

      <aside className="sidebarRight">
        <h2>Tendências</h2>
        <ul className="trendingList">
          {["#Exemplo1", "#Exemplo2", "#Exemplo3"].map((trend, index) => (
            <li key={index}>
              <strong>{trend}</strong> {Math.floor(Math.random() * 20)}K Tweets
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
};

export default Home;
